/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @next/next/no-img-element */
import { Button, buttonVariants } from '@/components/ui/button';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { cn } from '@/lib/cn';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { useState } from 'react';
import type { Path } from 'react-hook-form';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Loader } from '@/components/ui/loader';
import {
  CloudArrowUpIcon,
  PencilIcon,
  UserIcon,
} from '@heroicons/react/16/solid';
import { Document, Thumbnail, pdfjs } from 'react-pdf';
import type { FieldProps } from '../../types';
import { HiddenInput } from './components/hidden-input';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function File<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  label,
  description,
  className,
  fileFieldStyle = 'dropzone',
  fileFieldAccept,
  disabled,
  canHandleField,
}: FieldProps<Instance>) {
  const [isDragged, setIsDragged] = useState(false);
  if (fileFieldStyle === 'image') {
    return (
      <FormField
        control={formField.control}
        name={name as Path<Instance>}
        render={({ field }) => {
          const imageUrl = field.value?.get
            ? field.value?.get
            : field.value?.file
              ? URL.createObjectURL(field.value?.file)
              : undefined;
          return (
            <FormItem className={className}>
              <FormControl>
                <label htmlFor='dropzone-file'>
                  <div className='group relative inline-block hover:cursor-pointer'>
                    <div className='absolute inset-0 z-10 hidden rounded-full border-2 border-primary bg-black/10 group-hover:block' />
                    <Avatar className='size-24 border-2 border-primary shadow-[0px_16px_14px_0px_rgba(0,0,0,0.15)]'>
                      <AvatarImage
                        width={96}
                        height={96}
                        src={imageUrl}
                        alt={field?.value?.name}
                        className='object-cover'
                      />
                      <AvatarFallback>
                        <UserIcon className='size-8' />
                      </AvatarFallback>
                    </Avatar>
                    <label
                      htmlFor='dropzone-file'
                      className={cn(
                        buttonVariants({ size: 'icon' }),
                        'absolute bottom-0 right-0 z-10 rounded-full hover:cursor-pointer'
                      )}
                    >
                      <PencilIcon className='size-4' />
                      <HiddenInput
                        disabled={disabled ?? field.disabled ?? !canHandleField}
                        field={field}
                        setIsDragged={setIsDragged}
                        className='cursor-pointer'
                      />
                    </label>
                    <HiddenInput
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                      field={field}
                      setIsDragged={setIsDragged}
                    />
                  </div>
                </label>
              </FormControl>
            </FormItem>
          );
        }}
      />
    );
  }

  return (
    <FormField
      control={formField.control}
      name={name as Path<Instance>}
      render={({ field }) => {
        return (
          <FormItem className={className}>
            <FormControl>
              <div className='flex w-full items-center justify-center'>
                <label
                  htmlFor='dropzone-file'
                  className={cn(
                    'relative flex h-64 w-full flex-col items-center justify-center rounded-lg border-2  border-border bg-muted',
                    {
                      'border-primary': isDragged,
                      'border-dashed hover:bg-accent cursor-pointer':
                        !field.value,
                    }
                  )}
                >
                  <div className='flex flex-col items-center justify-center pb-6 pt-5'>
                    {field.value?.get ? (
                      <Button
                        disabled={disabled ?? field.disabled ?? !canHandleField}
                        variant='link'
                        className='h-auto p-0'
                        size='sm'
                        type='button'
                        onClick={(evt) => {
                          evt.stopPropagation();
                          evt.preventDefault();
                          field.onChange(null);
                        }}
                      >
                        Rensa vald fil
                      </Button>
                    ) : field.value ? (
                      <>
                        <span className='mb-2 text-xs'>
                          {field.value.file?.name}
                        </span>
                        {field.value.file?.name.includes('.jpg') ||
                          (field.value.file?.name.includes('.png') && (
                            <div className='my-4 rounded-lg shadow-[2px_2px_12px_3px_rgba(0,0,0,.3)]'>
                              <img
                                alt='Selected'
                                className='size-24 min-h-[96px] min-w-[96px] rounded-lg object-cover'
                                src={URL.createObjectURL(field.value?.file)}
                              />
                            </div>
                          ))}

                        {field.value?.file?.name.includes('.pdf') && (
                          <Document
                            className={'my-4'}
                            file={field.value?.file}
                            noData={() => (
                              <div className='relative flex h-10 items-center justify-center text-sm'>
                                Ingen fil att visa
                              </div>
                            )}
                            onLoadError={(err) => {
                              console.log(err);
                            }}
                            loading={() => (
                              <div className='relative size-full'>
                                <Loader message='Laddar fil...' />
                              </div>
                            )}
                          >
                            <Thumbnail
                              pageNumber={1}
                              width={96}
                              className='rounded-lg'
                            />
                          </Document>
                        )}

                        <Button
                          variant='link'
                          className='h-auto p-0'
                          size='sm'
                          type='button'
                          onClick={(evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                            field.onChange(null);
                          }}
                        >
                          Rensa vald fil
                        </Button>
                      </>
                    ) : (
                      <>
                        <CloudArrowUpIcon className='mb-4 size-8' />
                        <p className='mb-2 text-sm font-medium'>
                          {label ?? formField.label}
                        </p>
                        <p className='mb-2 px-4 text-center text-sm text-muted-foreground'>
                          <span className='font-semibold'>
                            Klicka för att välja
                          </span>{' '}
                          eller dra en fil och släpp här
                        </p>
                        <p className='text-xs '>
                          {fileFieldAccept?.join(', ')}
                        </p>
                      </>
                    )}
                  </div>
                  {!field.value && (
                    <HiddenInput
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                      accept={fileFieldAccept?.join(',')}
                      field={field}
                      setIsDragged={setIsDragged}
                      className='cursor-pointer'
                    />
                  )}
                </label>
              </div>
            </FormControl>
            <FormDescription>
              {description ?? formField?.description}
            </FormDescription>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}
