'use client';

import { Icons } from '@/components/icons';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import { usePerms } from '@/hooks/usePerms';
import { cn } from '@/lib/cn';
import { toReadableAddress } from '@/lib/utils';
import { useGetInfiniteList, useGetInstance } from '@/requests/hooks';
import {
  CheckIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import { type BaseInstance } from '@pigello/pigello-matrix';
import { keepPreviousData } from '@tanstack/react-query';
import { useMemo, useRef, useState } from 'react';
import { useFormContext, type Path } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import type { FieldProps } from '../../types';
import { DescriptionTooltip } from '../description-tooltip';
import CreateAddressInFieldModal from './components/create-modal';

export function AddressField<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  className,
  horizontal,
  disabled,
  filters,
  overrideRequired,
  overrideRelationConfigModelName,
  canHandleField,
}: FieldProps<Instance>) {
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const debouncedSearch = useDebounceCallback(setSearch, 300);
  const relationConfigName = formField.config?.fields[formField.name];
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const [initialData, setInitialData] = useState<Instance>();

  const { fields } = usePerms<Instance>(formField.config.modelName);

  const canView =
    fields[formField.name]?.canView === true ||
    fields[formField.name]?.canView === undefined;
  const { getValues } = useFormContext();
  const isNullable =
    formField.config.fields?.[name]?.nullable != null
      ? formField.config.fields?.[name]?.nullable
      : true;

  if (!('relationConfig' in relationConfigName)) {
    throw new Error('relationConfig is not in relationConfigName');
  }

  const id: string = getValues(name as Path<Instance>)?.id;

  const noInFilter = useMemo(() => {
    const clonedFilters = structuredClone(filters);

    const noInFilter = clonedFilters?.['id']?.['__in!'] as string;

    if (filters?.['id'] && filters?.['id']?.['__in!']) {
      filters['id']['__in!'] = undefined;
    }
    return noInFilter;
  }, [filters]);

  const idFilter = [id, noInFilter].filter(Boolean).join(',') || undefined;
  const relationConfigModelName =
    overrideRelationConfigModelName ?? relationConfigName.relationConfig;

  const { canCreate: canCreateAddress } = usePerms<Instance>(
    relationConfigModelName
  );

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, error } =
    useGetInfiniteList<Instance>({
      modelName: relationConfigModelName,
      queryParams: {
        filters: {
          ...filters,
          ...((id || noInFilter) && {
            id: {
              '__in!': idFilter,
            },
          }),
          archived: { __in: false },
        },
        page: 1,
        pageSize: id ? 24 : 25,
        search,
      },
      placeholderData: keepPreviousData,
    });

  const { data: singleInstance, isPending: isInitialLoadingSelectedInstance } =
    useGetInstance<Instance>({
      modelName: relationConfigModelName,
      id: id,
      enabled: !!id && canView && !disabled,
      initialData,
    });

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: !!error,
    rootMargin: '0px 0px 100px 0px',
  });

  const options = [singleInstance]
    .concat(
      data?.pages.flatMap((p) => p.list).filter((inst) => inst?.id !== id) ?? []
    )
    .filter(Boolean) as Instance[];

  return (
    <FormField
      control={formField.control}
      name={name as Path<Instance>}
      render={({ field }) => {
        const clearField = () => {
          field.onChange(null);
        };

        return (
          <>
            {expanded && (
              <CreateAddressInFieldModal
                closeFunction={() => setExpanded(false)}
                successCallback={(newAddress) => {
                  field.onChange(newAddress);
                }}
              />
            )}
            <FormItem
              className={cn(
                'flex flex-col justify-start gap-2 space-y-2',
                horizontal && 'flex-row',
                className
              )}
            >
              <div className={cn('w-1/2 shrink-0', !horizontal && 'w-full')}>
                <div className='flex w-full flex-col'>
                  <div className='flex items-center justify-between'>
                    <FormLabel>
                      {label ?? formField.label}{' '}
                      {(overrideRequired != null
                        ? overrideRequired
                        : formField.required) && <span>*</span>}
                    </FormLabel>
                    <div>
                      {canCreateAddress && (
                        <Tooltip>
                          <TooltipTrigger
                            asChild
                            disabled={
                              disabled ?? field.disabled ?? !canHandleField
                            }
                            type='button'
                            onClick={(e) => {
                              e.preventDefault();
                              setExpanded(true);
                            }}
                            className='flex items-center justify-center rounded-full transition-colors hover:bg-muted hover:text-foreground'
                          >
                            <Button
                              variant='link'
                              className='flex items-center gap-1 whitespace-nowrap p-0 text-xs'
                              size='icon'
                            >
                              Skapa ny
                              <PlusIcon className='size-4' />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Skapa ny adress</TooltipContent>
                        </Tooltip>
                      )}
                      {(description || formField.description) && (
                        <DescriptionTooltip
                          description={description ?? formField.description}
                        />
                      )}
                    </div>
                  </div>
                  <FormMessage />
                </div>
              </div>
              <Popover
                modal
                open={open}
                onOpenChange={(val) => {
                  if (!val) {
                    debouncedSearch('');
                  }
                  setInitialData(undefined);
                  setOpen(val);
                }}
              >
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant='outline'
                      ref={btnRef}
                      role='combobox'
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                      className={cn(
                        '!mt-0 min-h-10 w-full justify-between text-left align-middle font-normal  shadow-input',
                        !field.value?.id && 'text-muted-foreground',
                        horizontal && 'w-1/2 self-end'
                      )}
                    >
                      {field.value ? (
                        <div className='h-full truncate leading-normal'>
                          {isInitialLoadingSelectedInstance
                            ? 'Laddar...'
                            : `${toReadableAddress(singleInstance, true, false)}`}
                        </div>
                      ) : (
                        <div className='line-clamp-1 leading-none opacity-60'>
                          {placeholder ?? `Välj ${formField.label}`}
                        </div>
                      )}
                      <div className='flex items-center'>
                        {isNullable && field.value && (
                          <span
                            title='Rensa fältet'
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                clearField();
                              }
                            }}
                            role='button'
                            tabIndex={0}
                            onClick={(e) => {
                              e.stopPropagation();
                              clearField();
                            }}
                          >
                            <XMarkIcon className='size-4 opacity-50 transition-opacity hover:opacity-100' />
                          </span>
                        )}
                        <ChevronUpDownIcon className='ml-2 size-4 shrink-0 opacity-50' />
                      </div>
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent
                  style={{ width: btnRef.current?.offsetWidth }}
                  className={
                    'p-0 pb-1 shadow-[rgba(0,0,0,0.12)_0px_4px_30px,rgba(0,0,0,0.04)_0px_3px_17px,rgba(0,0,0,0.04)_0px_2px_8px,rgba(0,0,0,0.04)_0px_1px_1px] transition-all duration-300 ease-in-out'
                  }
                >
                  <Command shouldFilter={false}>
                    <CommandInput
                      isLoading={isLoading || isFetching}
                      placeholder={
                        placeholder ??
                        `Sök efter ${label ?? formField.label}...`
                      }
                      onValueChange={debouncedSearch}
                    />
                    <CommandEmpty>Inga sökningar hittades...</CommandEmpty>
                    <CommandList>
                      {!isFetching && (
                        <CommandEmpty className='flex items-center justify-center gap-2 font-medium text-muted-foreground'>
                          <MagnifyingGlassIcon className='size-6 opacity-50' />
                          Inga resultat hittades
                        </CommandEmpty>
                      )}
                      <CommandGroup
                        className={cn('max-h-60 w-auto overflow-y-scroll')}
                      >
                        {options.map((item) => (
                          <CommandItem
                            value={item.id}
                            key={item.id}
                            onSelect={() => {
                              if (item.id !== field.value?.id) {
                                field.onChange({ id: item.id });
                                setInitialData(item);
                              } else {
                                setInitialData(undefined);
                                field.onChange(null);
                              }
                              setOpen(false);
                            }}
                          >
                            <CheckIcon
                              className={cn(
                                'mr-2 size-4',
                                item.id === field.value?.id
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              )}
                            />
                            {toReadableAddress(item, true, false)}
                          </CommandItem>
                        ))}

                        {(isFetching || hasNextPage) && (
                          <div
                            ref={sentryRef}
                            className='flex size-full items-center justify-center'
                          >
                            <Icons.loader className='my-4 size-6 animate-spin' />
                          </div>
                        )}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </FormItem>
          </>
        );
      }}
    />
  );
}
